import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` is designed to be quick to setup. You'll need an account with Auth0 or Netlify Identity and the appropriate access keys.`}</p>
    <h2 {...{
      "id": "1-install-the-hook"
    }}>{`1. Install the hook`}</h2>
    <pre><code parentName="pre" {...{}}>{`$ yarn add react-use-auth
`}</code></pre>
    <p>{`Downloads from npm, adds to your package.json, etc. You can use npm as well.`}</p>
    <h2 {...{
      "id": "2-install-your-auth-client"
    }}>{`2. Install your auth client`}</h2>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` does not install its own authentication clients. Instead they're marked as peer dependencies.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`auth0-js`}</inlineCode>{` or `}<inlineCode parentName="p">{`netlify-identity-widget`}</inlineCode>{` depending on what you'd like to use. More coming soon :)`}</p>
    <pre><code parentName="pre" {...{}}>{`$ yarn add auth0-js
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`$ yarn add netlify-identity-widget
`}</code></pre>
    <p>{`You'll see warnings about missing peer dependencies for the client you aren't using. That's okay.`}</p>
    <h2 {...{
      "id": "3-configure-with-authconfig"
    }}>{`3. Configure with AuthConfig`}</h2>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` uses an `}<inlineCode parentName="p">{`<AuthConfig>`}</inlineCode>{` component to configure your authentication client. We use XState behind the scenes to manage authentication state for you.`}</p>
    <p>{`Ensure `}<inlineCode parentName="p">{`AuthConfig`}</inlineCode>{` renders on every page.`}</p>
    <p>{`With Gatsby, add it to `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{`. With NextJS, `}<inlineCode parentName="p">{`_app.js`}</inlineCode>{` is best. You don't `}<em parentName="p">{`need`}</em>{` to wrap your component tree, but you can if you prefer. We make sure useAuth doesn't break server-side rendering. ✌️`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// gatsby-browser.js
import * as React from "react";
import { navigate } from "gatsby";

import { AuthConfig } from "react-use-auth";
import { Auth0 } from "react-use-auth/auth0"

export const wrapRootElement = ({ element }) => (
    <>
        <AuthConfig
            navigate={navigate}
            authProvider={Auth0}
            params={{
                domain: "useauth.auth0.com",
                clientID: "GjWNFNOHqlino7lQNjBwEywalaYtbIzh"
            }}
        />
        {element}
    </>
);
`}</code></pre>
    <p><inlineCode parentName="p">{`<AuthConfig>`}</inlineCode>{` initializes the global XState state machine, sets up an Auth0 client, and validates user sessions on every load. You now have easy access to authentication in your whole app :)`}</p>
    <p>{`The config options are:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`navigate`}</inlineCode>{` – your navigation function, used for redirects. Tested with Gatsby, NextJS, and React Router. Anything should work.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`authProvider`}</inlineCode>{` – the useAuth interface to your authentication provider.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><inlineCode parentName="p">{`params`}</inlineCode>{` – parameters for your authentication provider`}</p>
      </li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` client wrappers provide smart defaults.`}</p>
    <p>{`More detail on using custom configuration for each client in `}<a parentName="p" {...{
        "href": "/docs/auth0"
      }}>{`Use with Auth0`}</a>{`, and `}<a parentName="p" {...{
        "href": "/docs/netlify-identity"
      }}>{`Use with Netlify Identity`}</a>{`. To learn about how this works, go to `}<a parentName="p" {...{
        "href": "/docs/auth-providers"
      }}>{`Create an auth provider`}</a></p>
    <p>{`PS: feel free to use my Auth0 domain and clientID to see if `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` is a good fit for you. That's why they're visible in the code snippet 😊`}</p>
    <h2 {...{
      "id": "4-create-the-callback-page"
    }}>{`4. Create the callback page`}</h2>
    <p>{`Auth0 and most other authentication providers use OAuth. That requires redirecting your user to their login form. After login, the provider redirects users back to your app.`}</p>
    <p>{`You can skip this step with Netlify Identity. It uses an in-page popup.`}</p>
    <p>{`Any way of creating React pages should work, here's the code I use for Gatsby:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import * as React from "react"
import { useAuth } from "react-use-auth"

const Auth0CallbackPage = () = {
    // this is the important part
    const { handleAuthentication } = useAuth()
    React.useEffect(() => {
        handleAuthentication()
    }, [handleAuthentication])
    // 👆

    return (
        <h1>
            This is the auth callback page,
            you should be redirected immediately!
        </h1>
    )
}

export default Auth0CallbackPage
`}</code></pre>
    <p>{`The goal is to load a page, briefly show some text, and run the `}<inlineCode parentName="p">{`handleAuthentication`}</inlineCode>{` method from `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` on page load.`}</p>
    <p>{`That method will create a cookie in local storage with your user's information and redirect back to homepage. You can pass a `}<inlineCode parentName="p">{`postLoginRoute`}</inlineCode>{` param to redirect to a different page.`}</p>
    <p>{`Make sure you add `}<inlineCode parentName="p">{`<domain>/auth0_callback`}</inlineCode>{` as a valid callback URL in your Auth0 config.`}</p>
    <h2 {...{
      "id": "5-enjoy-useauth"
    }}>{`5. Enjoy useAuth`}</h2>
    <p>{`You're ready to use `}<a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` for authentication in your React app. 🤘`}</p>
    <p>{`Here's a login button for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Login = () => {
    const { isAuthenticated, login, logout } = useAuth();

    if (isAuthenticated()) {
        return <button onClick={logout}>Logout</button>;
    } else {
        return <button onClick={login}>Login</button>;
    }
};
`}</code></pre>
    <p><inlineCode parentName="p">{`isAuthenticated`}</inlineCode>{` is a method that checks if the user's cookie is still valid.`}</p>
    <p><inlineCode parentName="p">{`login`}</inlineCode>{` and `}<inlineCode parentName="p">{`logout`}</inlineCode>{` trigger their respective actions.`}</p>
    <p>{`You can even say hello to your users:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/pages/index.js

const IndexPage = () => {
  const { isAuthenticated, user } = useAuth()

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Hi {isAuthenticated() ? user.name : "people"}</h1>
  )
}
`}</code></pre>
    <p>{`Check `}<inlineCode parentName="p">{`isAuthenticated`}</inlineCode>{` then use the `}<inlineCode parentName="p">{`user`}</inlineCode>{` object. ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      